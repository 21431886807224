// SRC: https://github.com/lukeed/clsx/blob/master/src/index.js

function toVal(mix: Arg): string {
  let k: number | string,
    y: string,
    str = '';

  if (typeof mix === 'string' || typeof mix === 'number') {
    str += mix;
  } else if (typeof mix === 'object') {
    if (Array.isArray(mix)) {
      for (k = 0; k < mix.length; k++) {
        if (mix[k]) {
          if ((y = toVal(mix[k]))) {
            str && (str += ' ');
            str += y;
          }
        }
      }
    } else {
      for (k in mix) {
        if (mix && mix[k]) {
          str && (str += ' ');
          str += k;
        }
      }
    }
  }

  return str;
}

type Arg =
  | false
  | boolean
  | undefined
  | string
  | null
  | Record<string, boolean>
  | Arg[];

export const classNames = function (...args: Arg[]): string {
  let i = 0,
    tmp: Arg,
    x: Arg,
    str = '';
  while (i < arguments.length) {
    if ((tmp = args[i++])) {
      if ((x = toVal(tmp))) {
        str && (str += ' ');
        str += x;
      }
    }
  }
  return str;
};
