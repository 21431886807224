import React from 'react';
import {Dict} from "../types/common";
import {useBooleanState} from "./useBooleanState";
import {useCallbackRef} from "./useCallbackRef";

export interface UseDisclosureReturn<TDisclosureData = Dict> {
  isOpen: boolean;
  open(data?: TDisclosureData): void;
  onOpen(data?: TDisclosureData): void;
  close(data?: TDisclosureData): void;
  onClose(data?: TDisclosureData): void;
  toggle(data?: TDisclosureData): void;
  setOpen(isOpen: boolean, data?: TDisclosureData): void;
  onOpenChange(isOpen: boolean, data?: TDisclosureData): void;
  isControlled: boolean;
  data?: TDisclosureData;
}

export interface UseDisclosureOptions<TDisclosureData extends Dict = Dict> {
  isOpen?: boolean;
  defaultIsOpen?: boolean;
  onClose?(): void;
  onOpen?(): void;
  data?: TDisclosureData;
}

export function useDisclosure<TData extends Dict = Dict>(
  options: UseDisclosureOptions<TData> = {}
): UseDisclosureReturn<TData> {
  const isOpenState = useBooleanState(options.defaultIsOpen || false);
  const [data, setData] = React.useState<TData>(options.data as TData);

  const handleOpen = useCallbackRef(options.onOpen);
  const handleClose = useCallbackRef(options.onClose);

  const isOpen =
    options.isOpen !== undefined ? options.isOpen : isOpenState.state;
  const isControlled = options.isOpen !== undefined;

  const open = React.useCallback(
    (data: TData) => {
      setData(data);
      if (!isControlled) {
        isOpenState.true();
      }
      handleOpen?.();
    },
    [isControlled, handleOpen]
  );

  const close = React.useCallback(
    (data: TData) => {
      if (data !== undefined) setData(data);
      if (!isControlled) {
        isOpenState.false();
      }
      handleClose?.();
    },
    [isControlled, handleClose]
  );

  const toggle = React.useCallback(
    (data: TData) => {
      setData(data);
      if (isOpen) {
        close(data);
      } else {
        open(data);
      }
    },
    [isOpen, open, close]
  );

  const setOpen = React.useCallback(
    (isOpen: boolean, data: TData) => {
      setData(data);
      if (!isOpen) {
        close(data);
      } else {
        open(data);
      }
    },
    [isOpen, open, close]
  );

  return {
    isOpen,
    open,
    onOpen: open,
    close,
    onClose: close,
    toggle,
    isControlled,
    data,
    setOpen,
    onOpenChange: setOpen
  };
}
