import React, { useState } from 'react';
import toast from 'react-hot-toast';

function logError(error: unknown) {
  console.error(error);
}

export interface StandardApiHandlerOptions {
  successToastMessage?: string;
  errorToastMessage?: string;
  onError?: (error: unknown) => void;
  onSuccess?: (response: unknown) => void;
}

export const useStandardApiHandler = (initialIsLoading = false) => {
  const [isLoading, setIsLoading] = useState(initialIsLoading);
  const [isSuccess, setIsSuccess] = useState(initialIsLoading);
  //const toast = useToast();

  function wrapApiRequest<TParams extends unknown[], TResponse>(
    request: (...params: TParams) => Promise<TResponse>,
    successToastMessageOrOptions?: string | StandardApiHandlerOptions,
    onError?: (error: unknown) => void
  ) {
    return async (...params: TParams): Promise<TResponse | void> => {
      const { successToastMessage, onSuccess } =
        typeof successToastMessageOrOptions === 'object'
          ? successToastMessageOrOptions
          : {
              successToastMessage: successToastMessageOrOptions,
              onSuccess: undefined
            };

      setIsSuccess(false);

      try {
        setIsLoading(true);
        const response = await request(...params);
        setIsLoading(false);

        if (successToastMessage) {
          toast.success(successToastMessage, {
            duration: 3000
          });
        }

        onSuccess?.(response);
        setIsSuccess(true);

        return response;
      } catch (error) {
        setIsLoading(false);

        logError(error);
        //const errorMessage = getApiErrorMessage(error);
        //const errorMessage = String(error);
        //toast.toastError(errorMessage);
        onError?.(error);
      }
    };
  }

  return {
    isLoading,
    isSuccess,
    wrapApiRequest
  };
};
